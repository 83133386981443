import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import * as CONST from "../../../components/const";
// import bg from "../../../images/employment/senior/bg.png";
const kv = "../../../images/employment/senior/kv.png";
//import header from "../../../images/employment/senior/header.jpg";
const img1 = "../../../images/employment/senior/img1.png";
const img2 = "../../../images/employment/senior/img2.png";
const img3 = "../../../images/employment/senior/img3.png";
const footerLeft = "../../../images/employment/senior/footer_left.jpg";
const footerRight = "../../../images/employment/senior/footer_right.jpg";

function IndexPage() {
	const STATIC_IMAGE_PLACEHOLDER = "blurred"; // blurred tracedSVG none

	return (
		<Layout>
			<SEO
				description="ALPHA WAVEの中堅社員からのメッセージです。是非ともご覧ください。"
				title="Senior"
			/>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.KEY_VISUAL_TITLE_MESSAGE_AREA_CLASS}>
					<div className={CONST.KEY_VISUAL_TITLE_AREA_CLASS}>
						<p className={CONST.KEY_VISUAL_TITLE_EN_CLASS}>Employment information <span className={CONST.KEY_VISUAL_TITLE_JP_CLASS}>採用情報</span></p>
					</div>
					<div className={CONST.KEY_VISUAL_MESSAGE_AREA_CLASS}>
						<div>
							<h2 className={CONST.H2_CLASS}>先輩社員からのメッセージ</h2>
							<h2 className={CONST.H2_CLASS}>土屋　タロウ</h2>
							<p className={CONST.H5_CLASS}>(2001年11月入社)国際学科卒</p>
						</div>
					</div>
				</div>
				<div className={CONST.KEY_VISUAL_RIGHT_HALF_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={kv} />
					<div className={CONST.KEY_VISUAL_RIGHT_HALF_MESSAGE_AREA_CLASS}>
						<div>
							<p>10年以上も仕事を続けてくることが出来たのは、</p>
							<p>会社にお手本とすべき人達がいたから</p>
						</div>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<p>2001年に入社した土屋です。</p>
						<p className={CONST.LINE_SPACING_PB_CLASS}>新規のお客様との関係を築く仕事を任せていただいた時のことをお話します。</p>
						<p>それまでチームリーダーの下で開発業務に携わってきましたが、入社10年目の頃、一人で新規のお客様の仕事を任せていただくことになりました。フォローしてもらうことが多い守ってもらう立場から、自分で仕事を切り開いていく立場へと変わったのです。不安もありましたが、楽しみな気持ちも大きかったことを覚えています。自分を成長させたい思いと、今の私の目標である「会社から受けた恩を返す」ことを叶えるチャンスだと考えたからです。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={img1} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-full lg:w-3/12"></div>
				<div className="w-full lg:w-9/12">
					<p>今まで沢山の方々にお世話になってきました。それは原点である新入社員研修まで遡ります。</p>
					<p>私は全くのプログラム未経験で入社をしたのですが、こうして技術者として10年以上も働ける基礎を築くことができたのは、研修のおかげだと思っています。当社は新入社員をより良く活かすことに注力しており、研修は私が入社した頃も大変手厚く、講師の先輩はとても丁寧に、必要な時には厳しくも教えてくれました。更に、私は研修を受ける立場であるのにお給料もいただけた。これはいつか仕事で恩返しをしようと誓ったのです。</p>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={img2} />
				</div>
				<div className={CONST.CONTENTS_RIGHT_MESSAGE_AREA_CLASS}>
					<div>
						<p className={CONST.H3_CLASS + CONST.LINE_SPACING_PB_CLASS}>「気の利いた対応」を大切にしています。</p>
						<p className="pb-4">…この先お伝えするのは、私が仕事で心がけていることや、それによって上手くいったことです。偉そうに聞こえてしまったら申し訳ないのですが、覚えておいて欲しいのは、決して最初からできていたわけではないということです。お手本にすべき上司や先輩、後輩と一緒に仕事を続けて、自然と身につけさせてもらった姿勢だと思っています。</p>
						<p>私の役目は、新規のお客様から信頼を得ることで多くの仕事を頂戴し、当社の社員を新たに配属させて現場を拡張していくことです。やりがいと共に大きな責任を感じました。新規のお客様にとっては土屋＝アルファ・ウェーブであり、私の評判が当社の評判になるからです。</p>
					</div>
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-full lg:w-3/12"></div>
				<div className="w-full lg:w-9/12">
					<p>私が当社で学び心がけているのは、まず「誠実・実直」であること。そして「気の利いた対応」です。</p>
					<p>「誠実・実直」の第一歩は、しっかりとした挨拶をすることだと考えています。当たり前かもしれませんが想像以上に大切なことで、お客様達との距離を縮めていく手助けとなります。それを続けることでお客様から話しかけていただける雰囲気を作り、日常の雑談のみならず、当社の事業範囲に対する質問や技術的な相談を受けるところまで関係が発展した時には、日々の小さな行動がチャンスを広げているんだと感じました。</p>
					<p>「気の利いた対応」は、システムエンジニアとして大切な姿勢です。私達の仕事は、システムを作ることで人々の不便を便利に変えることですから、どうすれば相手が楽になり助かるか？喜ぶか？と、常に考えて行動する力が求められています。</p>
					<p>これは、システム開発以外のところでも発揮すべき力です。例えば資料を作成する時は、頼まれたこと以上に役立つ物を作ってお渡しするとか、作業の経過を伝える時は、お客様に尋ねられる前に自分から的確に報告し安心していただくこと等です。小さなことかもしれませんが、挨拶と同様にその積み重ねが信頼に繋がるのだと考えています。こうして「同じことを頼むなら土屋さん（＝アルファ・ウェーブ）にお願いしよう」と言っていただける様にするのです。</p>
				</div>
			</section>

			<section className={CONST.SECTION_AREA_CLASS}>
				<div className={CONST.CONTENTS_LEFT_MESSAGE_AREA_CLASS}>
					<div>
						<p>嬉しかったことがありました。会話をする機会が増えたお客様に、ある日「アルファさんではこんな業務を行っていますか？仕事があるのですが…」と聞かれたので、すぐに本社に問い合わせをしました。残念ながら当社の事業範囲外でしたので協力することはかないませんでしたが、お客様に「スピーディーな対応をありがとうございます」とお礼の言葉をいただき、後日、別途に検証業務の打診をいただき、新しい仕事を増やすことができたのです。あの時の素早い対応が功を奏したのかもしれないと思いました。</p>
						<p>更に、こうしたことの積み重ねもあってか、今では若手社員を増員し、現場を少し大きくすることができました。大変ありがたいことに、充実感のある毎日をおくっています。</p>
					</div>
				</div>
				<div className={CONST.CONTENTS_RIGHT_IMG_AREA_CLASS}>
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={img3} />
				</div>
			</section>

			<section className={CONST.SECTION_P_AREA_CLASS}>
				<div className="w-full lg:w-9/12">
					<p className={CONST.H3_CLASS + CONST.LINE_SPACING_PB_CLASS}>技術力を高めることは勿論、人との信頼関係を深めることも出来る仕事</p>
					<p>これからも、お客様に長いお付き合いをしていただけるようご要望にお応えし、現場を拡張していくために努力していきます。もちろん技術を磨くことにも力を注ぎます。IT技術にはトレンドがありますから、その動向には常にアンテナを張っているつもりです。技術の他にも、お客様の業務知識を深めることでより良い提案をし、使いやすいシステムを作ることができるので、お客様の業界に関する情報収集も心がけています。</p>
					<p>システムエンジニアの仕事は大変なことも多いですが、それ以上にやりがいのある仕事だと思います。無の状態からシステムを作り上げるおもしろさと、それによりお客様に喜ばれる達成感。そして技術力はもちろん、人との信頼関係も深めることが出来るという魅力。私がずっとこの仕事を続けることが出来ているのも、当社の研修制度や社風の良さと、仕事のやりがいがあったからだと思います。</p>
					<p>最後に私事ですが、数年前に子供が生まれたこともありますので、これからも頑張っていきたいと思います！</p>
				</div>
				<div className="w-full lg:w-3/12"></div>
			</section>

			<section className={CONST.SECTION_PB_AREA_CLASS}>
				<div className="w-full md:w-1/12 lg:w-3/12"></div>
				<div className="w-full md:w-5/12 lg:w-3/12 p-5">
					<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={footerLeft} />
				</div>
				<div className="w-full md:w-5/12 lg:w-3/12 p-5">
					<Link key='senior' to='../young/'>
						<StaticImage quality="100" layout="fullWidth" alt="" placeholder={STATIC_IMAGE_PLACEHOLDER} className="w-full" src={footerRight} />
					</Link>
				</div>
				<div className="w-full md:w-1/12 lg:w-3/12"></div>
			</section>
		</Layout>
	);
}

export default IndexPage;
